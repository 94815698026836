
import { defineComponent } from 'vue';
import axios from "axios";
interface FILE_INFO {
  type: number,
  type_name: string,
  name: string,
  ext: string,
  size: number,
  birth: number,
  latest: number,
}

interface CLIENT_INFO {
    _expired:boolean,
    _device_serial:string|undefined,
}
export default defineComponent({
  data() {
    const dev_types = [];
    const edges:string[] = ['fc', 'hy'];
    const boards:string[] = ['ra', 'ag', 'na', 'nx'];
    const sws:string[] = ['ar', 'px'];
    const builds:string[] = ['de', 'cu'];
    let type = 0;
    for(let edge of edges) {
        for(let board of boards) {
            if(edge == 'hy') {
              ++type
              dev_types.push(
                {
                  tmp: `${edge}_${board}`,
                  name: `${edge}_${board}(${type})`,
                  value: type
                }
              );
            }
            else {
                for(let sw of sws) {
                    for(let build of builds) {
                      ++type
                      dev_types.push(
                        {
                          tmp: `${edge}_${board}${sw}${build}`,
                          name: `${edge}_${board}${sw}${build}(${type})`,
                          value: type
                        }
                      );
                    }
                }
            }
        }
    }

    dev_types.forEach((value) => {
      console.log(`${value.tmp}: ${value.value}`)
    });

    return {
      dev_types: dev_types,
      update_url: '/client/update',
      update_device_serial: '',
      update_type: 1,
      update_version: '1.0.0',
      test_url: '/version/all',
      test_result: '',
      edge_type: 0,
      edge_type_rule: [
        (v:number) => /^[1-9]$/.test(v.toString())
      ],
      edge_type_items: [
        {
          name: 'EDGE',
          value: 0,
        },
        {
          name: 'FC Controller',
          value: 1,
        },
        {
          name: 'Hydrogen',
          value: 2,
        },
      ],
      board_type: 0,
      board_type_rule: [
        (v:number) => /^[1-9]$/.test(v.toString())
      ],
      board_type_items: [
        {
          name: 'BOARD',
          value: 0,
        },
        {
          name: 'RasberryPi',
          value: 1,
        },
        {
          name: 'AGX',
          value: 2,
        },
        {
          name: 'Nano',
          value: 3,
        },
        {
          name: 'NX',
          value: 4,
        },
      ],
      sw_type: 0,
      sw_type_rule: [
        (v:number) => /^[1-9]$/.test(v.toString())
      ],
      sw_type_items: [
        {
          name: 'SW',
          value: 0,
        },
        {
          name: 'Ardupilot',
          value: 1,
        },
        {
          name: 'PX4',
          value: 2,
        },
      ],
      custom_build_type: 0,
      custom_build_type_rule: [
        (v:number) => /^[1-9]$/.test(v.toString())
      ],
      custom_build_type_items: [
        {
          name: 'BUILD',
          value: 0,
        },
        {
          name: 'Default',
          value: 1,
        },
        {
          name: 'Custom',
          value: 2,
        },
      ],
      version: '' as string,
      version_rule: [
        (v:string) => /^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{1,2}$/.test(v)
      ],
      file: null,
      file_list: [] as FILE_INFO[],
      client_list: [] as CLIENT_INFO[],
      tb_header: [
        // {
        //   text: 'Type',
        //   value: 'type'
        // },
        {
          text: 'TypeName',
          value: 'type_name'
        },
        {
          text: 'VERSION',
          value: 'version'
        },
        {
          text: 'Size',
          value: 'size'
        },
        {
          text: 'Created',
          value: 'birth'
        },
        {
          text: 'Action',
          value: 'actions',
          sortable: false,
        },
      ]
    }
  },

  mounted() {
    // console.log('mounted')
    this.RefreshFileList();
    this.RefreshClientList();

    setInterval(() => {
      this.RefreshClientList();
    }, 5000)
  },

  created() {
    // console.log('created')
  },

  methods: {
    Update() {
      try {
        axios.post(this.update_url, {device_serial: this.update_device_serial, filename: this.dev_types[this.update_type - 1].tmp + '_' + this.update_version})
        .then((value) => {
          // 
        })
        .catch((reason) => {
          console.error(reason);
        })
      } catch (error) {
        console.error(error);
      }
    },

    Test() {
      try {
        axios.get(this.test_url)
        .then((value) => {
          this.test_result = value.data;
        })
        .catch((reason) => {
          console.error(reason);
          this.test_result = reason;
        })
      } catch (error) {
        console.error(error);
      }

    },

    onClickUpload() {
      if(!this.version_rule[0](this.version)) {
        alert('Version re:check (xx.xx.xx)');
        return;
      }

      if(this.edge_type == 0) {
        alert('Edge type re:check');
        return;
      }

      if(this.board_type == 0) {
        alert('Board type re:check');
        return;
      }

      if(this.edge_type == 1 && this.sw_type == 0) {
        alert('SW type re:check');
        return;
      }

      if(this.edge_type == 1 && this.custom_build_type == 0) {
        alert('Build type re:check');
        return;
      }

      if (this.file) {
        let frm: FormData = new FormData();
        const edge_type = this.edge_type == 1 ? 'fc' : 'hy';
        const board_type = 
        this.board_type == 1 ? 'ra' :
        this.board_type == 2 ? 'ag' :
        this.board_type == 3 ? 'na' : 'nx';
        const sw_type = this.sw_type == 1 ? 'ar' : 'px';
        const custom_build_type = this.custom_build_type == 1 ? 'de' : 'cu';
        // frm.append('filename', edge_type + '_' + board_type + sw_type + custom_build_type + '_' + this.VersionToFilename(this.version));
        frm.append('filename', `${edge_type}_${board_type}${this.edge_type == 1 ? sw_type : ''}${this.edge_type == 1 ? custom_build_type : ''}_${this.version}`);
        frm.append('file', this.file);
        axios.postForm('/upload'
          , frm
          , {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(() => {
            this.RefreshFileList();
          })
      }

    },

    RefreshClientList() {
      axios.get('/client').then((value) => {
        this.client_list = value.data;
      })
    },

    RefreshFileList() {
      axios.get('/version/all').then((value) => {
        this.file_list = value.data;
      })
    },

    DownloadFile(file_name: string) {
      axios.get(`/uploads/${file_name}`, { responseType: 'blob' }).then((value) => {
        const downloadLink = document.createElement("a");
        downloadLink.download = `${file_name}`;
        downloadLink.href = window.URL.createObjectURL(value.data);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        window.URL.revokeObjectURL(downloadLink.href);
        document.body.removeChild(downloadLink);
      })
    },

    DeleteFile(file: FILE_INFO) {
      console.log(file);
      axios.delete(`/version/${file.name}`)
      .then(() => {
        this.RefreshFileList();
      })
    },  

    FilenameToVersion(file_name:string) {
      const splited = file_name.split('_');
      let converted_name = '';
      splited.forEach((str:string, index:number) => {
        if(index == 0) return;
        converted_name += str
        if(index != splited.length -1) converted_name += '.'
      });
      return converted_name;
    },

    VersionToFilename(version_name:string) {
      const splited = version_name.split('.');
      let converted_name = '';
      splited.forEach((str:string, index:number) => {
        converted_name += str
        if(index != splited.length -1) converted_name += '_'
      });
      return converted_name;
    },
  }
})
